@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  body {
    font-family: "Manrope", sans-serif;
    overflow-x: hidden;
  }
}

.oval-bottom {
  width: 100%;
  border-radius: 50% / 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.oval-top {
  width: 100%;
  border-radius: 50% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.overflowLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media not all and (min-width: 768px) {
  .max-md\:hidden {
    display: none;
  }
}

@media not all and (min-width: 1024px) {
  .max-lg\:hidden {
    display: none;
  }
}

@media not all and (min-width: 768px) {
  .max-md\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media not all and (min-width: 768px) {
  .max-md\:h-\[290px\] {
    height: 290px;
  }
}

@media not all and (min-width: 768px) {
  .max-md\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
